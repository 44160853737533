<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline >
        <c-btn 
          v-show="editable && !requestDisabled && isOccDept" 
          :isSubmit="isRequest"
          :url="completeUrl"
          :param="accidentInfo"
          mappingType="PUT"
          label="검토요청" 
          icon="check"
          @beforeAction="requestAccident"
          @btnCallback="completeCallback" />
        <c-btn 
          v-show="editable && !approveDisabled && isEnvSafDept" 
          :isSubmit="isApprove"
          :url="completeUrl"
          :param="accidentInfo"
          mappingType="PUT"
          label="승인" 
          icon="check"
          @beforeAction="approveAccident"
          @btnCallback="completeCallback" />
        <c-btn 
          v-show="editable && !returnDisabled && isEnvSafDept" 
          :isSubmit="isReturn"
          :url="completeUrl"
          :param="accidentInfo"
          mappingType="PUT"
          label="반려" 
          icon="front_hand" color="red-3"
          @beforeAction="returnAccident"
          @btnCallback="completeCallback" />
      </q-btn-group>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="tabHeight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :key="tab.key"
            :param.sync="param"
            :accidentInfo.sync="accidentInfo"
            :requestContentsCols="requestContentsCols"
            :disabled.sync="tabDisabled"
            :height.sync="height"
            :regUpdateBtnData.sync="param.regUpdateBtnData"
            @getDetail="getDetail"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>

import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-cause-prevention',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
        uid: '',
        regUpdateBtnData: {
          title: '사고등록',
          flag: false,
          research: '',
          planResearch: '',
          recResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      accidentInfo: {
        occurrenceDeptCd: '',
        accidentStatusCd: '',
        accidentCause: '',
        engineeringRecurrence: '',
        managementRecurrence: '',
        accidentImproveModelList: [],
      },
      splitter: 5,
      tab: 'accidentCausePreventionInfo',
      completeUrl: '',
      isComplete: false,
      isRequest: false,
      isApprove: false,
      isReturn: false,
      deleteUrl: '',
    };
  },
  watch: {
    'param.regUpdateBtnData.recResearch'() {
      this.getDetail();
    }
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 40
      return numHeight + 'px';
    },
    // 나중에 바꿔야함 -> 권한별로 
    isEnvSafDept() { 
      return this.$_.indexOf(this.$store.getters.auths, 'SAGI000025') > -1 || this.$store.getters.user.empNo === 'admin'
    },
    disabled() {
      return this.param.stepCd === 'ISPC000005'
    },
    tabDisabled() {
      return this.param.stepCd === 'ISPC000005' && !this.isEnvSafDept
    },
    isOccDept() { 
      return this.accidentInfo.occurrenceDeptCd === this.$store.getters.user.deptCd
    },
    requestDisabled() {
      return this.disabled || this.accidentInfo.accidentStatusCd === 'ISPC000004'
    },
    approveDisabled() {
      return this.disabled || this.accidentInfo.accidentStatusCd === 'ISPC000003'
    },
    returnDisabled() {
      return this.disabled || this.accidentInfo.accidentStatusCd === 'ISPC000003'
    },
    tabItems() {
      return [
        // 원인 및 재발방지
        { key: uid(), name: 'accidentCausePreventionInfo', icon: 'person_search', label: '원인 및 <br> 재발방지', component: () => import(`${'./accidentCausePreventionInfo.vue'}`) },
         // 피해정도 사진
        { key: uid(), name: 'processRelationPicture', icon: 'sick', label: '피해정도<br>사진', component: () => import(`${'./processRelationPicture.vue'}`), },
      ]
    },
    requestContentsCols() {
      return ['accidentCause'];
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.prevention.get.url;
      this.completeUrl = transactionConfig.sop.iim.accident.process.prevention.complete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.accidentInfo = _result.data;
        },);
      }
    },
    requestAccident() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '검토요청하시겠습니까?', 
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.accidentStatusCd = 'ISPC000004'
          this.accidentInfo.regUserId = this.$store.getters.user.userId
          this.accidentInfo.chgUserId = this.$store.getters.user.userId
          
          this.isRequest = !this.isRequest;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approveAccident() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '승인하시겠습니까?<br>(종결처리 상태로 변경됩니다.)', 
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.accidentStatusCd = 'ISPC000005'
          this.accidentInfo.regUserId = this.$store.getters.user.userId
          this.accidentInfo.chgUserId = this.$store.getters.user.userId
          
          this.isApprove = !this.isApprove;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    returnAccident() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '반려하시겠습니까?<br>(사고등록 상태로 변경됩니다.)', 
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.accidentStatusCd = 'ISPC000003'
          this.accidentInfo.regUserId = this.$store.getters.user.userId
          this.accidentInfo.chgUserId = this.$store.getters.user.userId
          
          this.isReturn = !this.isReturn;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.accidentInfo.accidentStatusCd) {
        this.$emit('emitStep', {
          name: 'currentStep',
          param: this.accidentInfo.accidentStatusCd 
        })
      }
      this.param.regUpdateBtnData.planResearch = uid();
    },
  }
};
</script>
